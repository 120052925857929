import { arraySort } from '@/util/Util'
import { isArray, isString } from 'lodash'

export default class ObjectRepository {
    objects: Array<any>
    suggestableProperties: Array<string> = ['id']

    constructor(objects = []) {
        this.objects = objects
    }

    static fromJson(jsonObject) {
        if ('objects' in jsonObject && isArray(jsonObject.objects)) {
            const newRepository = new this(jsonObject.objects)
            newRepository.suggestableProperties = jsonObject.suggestableProperties
        return newRepository
        } else {
            throw 'Invalid jsonObject to initialize an ObjectRepository!'
        }
    }

    addObject(object) {
        this.objects.push(object)
    }

    setObjects(objects) {
        this.objects = objects
    }

    sortObjects(prop, order = 'asc') {
        const sign = order === 'asc' ? 1 : -1
        this.objects.sort(arraySort(prop, sign))
    }

    clearObjects() {
        this.setObjects([])
    }

    getSuggestion(term, properties:null|Array<string>=null) {
        const searchIn = properties ? properties : this.suggestableProperties

        const foundPosition = this.objects.findIndex((object) => {
            return searchIn.some( (property) => {
                if (isString(object[property]) && property in object) {
                    const regex = new RegExp(`.*${term}.*`, 'gi')
                    return regex.test(object[property])
                } else {
                    return false
                }
            })
        })

        if (foundPosition !== -1) {
            return this.objects[foundPosition]
        } else {
            return null
        }
    }
}